import React, { useEffect } from 'react';

const PrivacyPage = () => {
  const redirectToPage = () => {
    window.location.replace('https://legal.justt.me/DataPrivacyStatement_de.html');
  };

  useEffect(() => {
    redirectToPage();
  }, []);

  return <div />;
};

export default PrivacyPage;
